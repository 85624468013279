import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import ThemeService from "../../components/app/admin/Services/ThemeService";
import toastr from "toastr";
import environment from "../../libs/environment";
import store from "store2";

import { logout } from "../../components/app/public/auth/AuthenticationActions";

import "./styles.scss";
//import user from '../../assets/img/user-solid.svg';
import logoImported from "../../assets/img/logo.svg";
import menu from "../../assets/img/menu.svg";

import Drawer from "../../components/DrawerMenu";
import Avatar from "../../components/Avatar";
import FeelModal from "../../components/FeelModal";

import MetricPassword from "../../components/app/admin/common/Dialogs/MetricPassword";

import MetricPreference from "../../components/app/admin/common/Dialogs/MetricPreference";
import PermIdentityOutlinedIcon from '@material-ui/icons/PermIdentityOutlined';
import UserServices from "../../components/app/admin/Services/UserService";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import AutorenewOutlinedIcon from '@material-ui/icons/AutorenewOutlined';
import SentimentSatisfiedAltOutlinedIcon from '@material-ui/icons/SentimentSatisfiedAltOutlined';
import ExitToAppOutlinedIcon from '@material-ui/icons/ExitToAppOutlined';
import Menu from "@material-ui/core/Menu";
// import HeaderMenu from "../HeaderMenu";

import useMusic from "../../hooks/useMusic";

import songMotivarnos from "../../assets/music/Shingle1.mp3";
import PlayerButton from "../../components/PlayerButton";

const TOKEN_LIFE = 2 * 60 * 60 * 1000;

const Header = (props) => {
  const dispatch = useDispatch();
  const [openUserMenu, setOpenUserMenu] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);
  const [openConfig, setOpenConfig] = useState(false);
  const { t } = useTranslation();
  const [OpenFeelModal, setOpenFeelModal] = useState(false);
  const [logo, setLogo] = useState(false);
  const [isPlaying, togglePlayMusic] = useMusic(songMotivarnos);

  useEffect(() => {
    if (!window.localStorage.getItem("theme")) {
      ThemeService.getThemePublic()
        .then((response) => {
          setLogo(response.data.logo);
        })
        .catch(() => {
          toastr.error("Ha ocurrido un error al intentar obtener el diseño.");
        });
    } else {
      setLogo(JSON.parse(window.localStorage.getItem("theme")).logo);
    }
  }, []);

  const handleCloseFeelModal = () => {
    setOpenFeelModal(!OpenFeelModal);
    setOpenConfig(false);
  };

  const handleOpenUserMenu = () => {
    setOpenUserMenu(!openUserMenu);
  };

  const handleOpenMenu = () => {
    setOpenMenu(!openMenu);
  };

  const handleOpenConfig = () => {
    setOpenConfig(!openConfig);
  };

  const changeToAdmin = () => {
    UserServices.goToAdmin()
      .then((response) => {
        store.clearAll();
        localStorage.setItem(
          "sesion",
          JSON.stringify({
            ...response.data,
            expiresAt: new Date().getTime() + TOKEN_LIFE,
          })
        );
        store("token", response.data.token);
        store("loggedAs", "ADMIN");
        window.location.replace("/");
      })
      .catch((error) => {
        if (error.response.status === 422) {
          toastr.error(
            t(
              "admin.user-panel-user-profile-admin-error",
              "You do not have a admin profile"
            )
          );
        } else {
          toastr.error(
            t(
              "admin.user-panel-user-profile-admin",
              "An error occurred while trying to switch admin"
            )
          );
        }

        handleOpenConfig();
      });
  };

  return (
    <div
      className={
        "header-wrapper " +
        (localStorage.getItem("sesion") &&
        JSON.parse(localStorage.getItem("sesion")).souceType === "TALKDESK"
          ? "header-wrapper-custom"
          : "")
      }
    >
      <div className="settings-container">
        <span className="menu-sm-icon-container" onClick={handleOpenMenu}>
          <img src={menu} alt="" />
        </span>
        <span className="logo-container">
          <NavLink to="/">
            <img
              className="img-header"
              src={logo ? logo : logoImported}
              alt=""
            />
          </NavLink>
        </span>
        <span className="user-container" onClick={handleOpenUserMenu}>
          <NavLink to="/avatar" onClick={handleOpenUserMenu}>
            <Avatar
              size={"2em"}
              style={{ borderWidth: "0px" }}
              avatar={`${environment.motivarnosBackend}${props?.avatar.substring(
                4
              )}`}
            />
          </NavLink>
        </span>
        <span className="name-player" onClick={handleOpenConfig}>
          {
            <>
              <span className="subname">
                {(props.name !== null ? props.name[0].toUpperCase() + props.name.substring(1) : "")}
              </span>
              <span className="arrow-name">
                <KeyboardArrowDownIcon
                  open={openUserMenu}
                  onClose={handleOpenUserMenu}
                  keepMounted
                  style={{fill: 'white', position: 'absolute', bottom: '20px', fontSize: '2rem'}}
                  className="user-menu-wrapper-material"
                ></KeyboardArrowDownIcon>
              </span>
            </>
          }
        </span>
        <Drawer
          open={openMenu}
          onClose={handleOpenMenu}
          anchor="left"
          DrawerClassName="sm-menu-drawer-wrapper"
        >
          <div className="sm-navigation-wrapper">
            <ul className="sm-menu">
              {props.options.map((data, index) => {
                return (
                  <li
                    key={index}
                    className="sm-menu-item"
                    onClick={handleOpenMenu}
                  >
                    <NavLink
                      to={data.link.substring(1)}
                      activeClassName="sm-link-active"
                    >
                      <span className="sm-menu-img-container">
                        <img
                          src={environment.motivarnosBackend + data.image}
                          alt="Link"
                        />
                      </span>
                    </NavLink>
                  </li>
                );
              })}
            </ul>
          </div>
        </Drawer>

        <Menu
          open={openConfig}
          onClose={handleOpenConfig}
          keepMounted
          className="config-menu-wrapper"
          anchorEl={null}
        >
          <NavLink to="avatar" onClick={handleOpenConfig}>
            <span className="menu-item">
              <PermIdentityOutlinedIcon
                className="icon-menu-item"
              /> Avatar</span>
          </NavLink>

          <NavLink to="avatar" onClick={changeToAdmin}>
            <span className="menu-item">
              <AutorenewOutlinedIcon className="icon-menu-item" />{" "}
              {t(
                "admin.user-panel-user-switch-admin",
                "Switch to administrator"
              )}
            </span>
          </NavLink>

          <NavLink to="avatar">
            <MetricPassword
              handleClose={() => handleOpenConfig()}
              playerView={true}
            />
          </NavLink>
          <span
            className="menu-item"
            onClick={handleCloseFeelModal}
            style={{ width: "100%" }}
          >
            <SentimentSatisfiedAltOutlinedIcon className="icon-menu-item" />
            {t("how-do-you-feel-today.label", "How do you feel today")}
          </span>

          <span className="menu-item" style={{ width: "100%" }}>
            <MetricPreference
              handleClose={() => handleOpenConfig()}
              type={"PLAYER"}
            />
          </span>
          <hr class="horizontal-line-menu"/>
          <span
            className="menu-item danger"
            onClick={() => dispatch(logout())}
          >
            <ExitToAppOutlinedIcon className="icon-menu-item" />
            {t("admin.header-dropdown-user-leave", "Leave")}
          </span>
        </Menu>

        <PlayerButton
          isPlaying={isPlaying}
          handlePlay={() => togglePlayMusic()}
        />
      </div>
      <FeelModal onClose={handleCloseFeelModal} open={OpenFeelModal} />
    </div>
  );
};

Header.defaultProps = {
  name: "",
  lastName: "",
  userName: "",
  email: "",
  avatar: null,
};

export default Header;
