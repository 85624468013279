import React, { useState, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import toastr from "toastr";
import { SpinnerCircular } from "spinners-react";
import Grid from "@material-ui/core/Grid";
import { useTranslation } from "react-i18next";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import SuggestionInboxService from "../../Services/SuggestionInboxService";
import TableCell from "@material-ui/core/TableCell";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import "../../Styles/UserDialog.scss";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  textField: {
    width: "50ch",
    marginBottom: "5px",
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function OwnerDialog(props) {
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const [userOwnerId, setUserOwnerId] = useState(0);
  const [username, setUsername] = useState('');

  const { t } = useTranslation();

  useEffect(() => {
    if (props.suggestionInbox.user_owner != undefined) {
      setUsername(props.suggestionInbox.user_owner.name);
      setUserOwnerId(props.suggestionInbox.user_owner.id);
    }
  }, []);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  
  const handleChange = (event) => {
    const { value } = event.target;

    setUserOwnerId(value);
  }

  async function onFormSubmit() {
    setLoading(true);

    /*if(userOwnerId == '') {
      toastr.error(
        t(
          "responsible-required",
          "Responsible Required"
        )
      );
      setLoading(false);
      return;
    }*/

    let status;

    await SuggestionInboxService.updateSuggestionInbox({ id: props.suggestionInbox.id, user_owner: {id: userOwnerId}})
      .then((r) => (status = r));

    props.suggestionInboxUpdate(status.data)

    if (status.status === "ERROR") {
      toastr.error(
        t(
          "error-assigning-responsible",
          "Error Assigning Responsible"
        )
      );

      setLoading(false);
    } else {
      toastr.success(
        t(
          "success-assigning-responsible",
          "Success Assigning Responsible",
        )
      );

      setUsername(status.data.user_owner.name);
      setLoading(false);
      handleClose();
    }
  }

  return (
    <>
      <TableCell
        onClick={handleClickOpen}
        align="left"
        className="pointer testTableCell"
      >
        {username.length > 30
          ? username.substring(0, 30) + "..."
          : username}
      </TableCell>
        
      <Dialog
        maxWidth="sm"
        fullWidth={true}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          {t("responsible", "Responsible")}
        </DialogTitle>

        {loading && (
          <div className="div-spinner-modal">
            <DialogContent dividers>
              <Grid container spacing={1}>
                <SpinnerCircular size={90} />
              </Grid>
            </DialogContent>
          </div>
        )}

        {!loading && (
          <>
            <DialogContent dividers>
              <FormControl variant="outlined" style={{width: '100%'}}>
                <InputLabel id="isActive-select-outlined-label">
                  {t("responsible", "Responsible")}
                </InputLabel>
                <Select
                  name="suggestionInboxOwner"
                  label={t("responsible", "Responsible")}
                  value={userOwnerId}
                  onChange={handleChange}
                >
                  <MenuItem key={0} value={0}>
                    Sin Usuario Responsable
                  </MenuItem>
                  {props.suggestionInboxOwner.map((user) => (
                    <MenuItem key={user.id} value={user.id}>
                      {user.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="secondary">
                {t("admin.header-dropdown-dialog-actions-cancel", "Cancel")}
              </Button>
              <Button onClick={onFormSubmit} color="primary">
                {t("admin.header-dropdown-dialog-actions-save", "Save")}
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    </>
  );
}
